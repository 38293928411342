import React, { Component } from "react"
import LocalData from "helpers/LocalStorage"
import Input from "components/forms/Input"
import api from "helpers/api"
import ForgotPassword from "./ForgotPassword"
import ChangePassword from "./ChangePassword"

export default class Login extends Component {
    constructor(props) {
        super(props)

        let searchParams = (new URL(window.location)).searchParams

        this.state = {
            online: navigator.onLine,
            anvandarnamn: "",
            losenord: "",
            navigation: searchParams.get("token") ? "reset-callback" : "login",
            loginError: "",

            forgotPassEmail: "",
            emailSent: false,
            emailSentError: false,
            nyttLosenord: "",
            repeteraNyttLosenord: "",
            resetError: "",
            emailSending: false,
            passwordChanging: false,
        }
    }

    componentDidMount() {
        document.addEventListener("keydown", this.onKeyPress)
        window.addEventListener("online", () => this.setState({online: true}))
        window.addEventListener("offline", () => this.setState({online: false}))
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.onKeyPress)
        window.removeEventListener("online", () => this.setState({online: true}))
        window.removeEventListener("offline", () => this.setState({online: false}))
    }

    onKeyPress = (event) => {
        if (event.key === "Enter") {
            switch(this.state.navigation) {
                case "login":
                    const { anvandarnamn, losenord } = this.state
                    if (!anvandarnamn || !losenord) this.setMissingFocus()
                    else this.login()
                    break
                case "reset":
                    const { emailSending, emailSent, forgotPassEmail } = this.state
                    if (!emailSending && !emailSent && forgotPassEmail) this.begarNyttLosenord()
                    break
                case "reset-callback":
                    const { nyttLosenord, repeteraNyttLosenord } = this.state
                    if (nyttLosenord && repeteraNyttLosenord) this.bytLosenord()
                    break
                default: break
            }

        }
    }

    onChange = (event) => {
        const { name, type, checked } = event.target
        let value = type === "checkbox" ? checked : event.target.value
        this.setState({[name]: value})
    }

    loginFailed() {
        this.setState({
            loginError: "Fel användarnamn eller lösenord"
        })
    }

    setMissingFocus() {
        const { anvandarnamn, losenord } = this.state
        if (!anvandarnamn) document.getElementById("anvandarnamn-input").focus()
        else if (!losenord) document.getElementById("losenord-input").focus()
    }

    md5(string) {
        var MD5 = require("crypto-js/md5")
        return MD5(string).toString()
    }

    login = async () => {
        const { anvandarnamn, losenord } = this.state
        if (!navigator.onLine || !anvandarnamn || !losenord) return
        const krypteratLosenord = this.md5(process.env.REACT_APP_SALT + this.md5(losenord))
        const result = await api.login(krypteratLosenord, anvandarnamn)

        if (result.response === "OK") {
            const anvandardata = {
                namnkod: result.namnkod.trim().toUpperCase(),
                namn: result.namn.trim(),
                apiNyckel: result.apikey
            }

            LocalData.set("anvandare", anvandardata)
            LocalData.remove("installningar")
            this.props.login()
        } else {
            this.loginFailed()
        }
    }

    onNavigate = (location) => {
        if (location === "callback") window.location = window.location.origin + window.location.pathname
        else this.setState({navigation: location, emailSentError: false})
    }

    begarNyttLosenord = async () => {
        this.setState({emailSending: true, emailSentError: false})
        const { forgotPassEmail } = this.state

        try {
            let result = await api.requestPasswordChange(forgotPassEmail)
            if (result.response === "OK") {
                this.setState({ emailSent: true, emailSending: false })
            } else {
                this.setState({ emailSentError: true, emailSending: false })
            }
        } catch(e) {
            this.setState({ emailSentError: true, emailSending: false })
        }
    }

    bytLosenord = async () => {
        this.setState({passwordChanging: true})
        const { nyttLosenord, repeteraNyttLosenord } = this.state

        if (nyttLosenord.trim() !== repeteraNyttLosenord.trim()) return this.setState({resetError: "Lösenorden matchar inte"})

        let searchParams = (new URL(window.location)).searchParams
        const resetToken = searchParams.get("token")
        const email = searchParams.get("epost")

        let result = await api.changePassword(resetToken, email, nyttLosenord)
        if (result.response === "OK") {
            window.location = "/samla"
        } else {
            const resetError = "Token är ogiltig eller har löpt ut"
            this.setState({resetError, passwordChanging: false})
        }
    }

    render() {
        const { onNavigate, onChange, bytLosenord, begarNyttLosenord, state } = this
        const { anvandarnamn, losenord, loginError, navigation, forgotPassEmail, emailSent, emailSentError,
            visaLosenord, nyttLosenord, repeteraNyttLosenord, resetError, online, emailSending, passwordChanging
        } = state

        return (
            <div className="login-background">
                <div className="login-modal">
                    <div className="login-modal-content">
                        {{
                            "login":
                                <React.Fragment>
                                    <div className="login-input-container">
                                        <div className="element">
                                            <Input id={"anvandarnamn-input"} label="Användarnamn" type="email" placeholder="exempel@sgu.se" value={anvandarnamn} name="anvandarnamn" onChange={this.onChange} />
                                        </div>

                                        <div className="element">
                                            <Input id={"losenord-input"} label="Lösenord" type="password" value={losenord} name="losenord" onChange={this.onChange} />
                                        </div>
                                    </div>

                                    <div className="element">
                                        {loginError && <p className="element danger small">{loginError}</p>}
                                        <button disabled={!online} onClick={this.login}>
                                            <p>Logga in</p>
                                            {!online && <i className="material-icons">wifi_off</i>}
                                        </button>
                                    </div>
                                    <p className="nav-link-container"><span className="nav-link" onClick={() => this.onNavigate("reset")}>Glömt ditt lösenord?</span></p>
                                </React.Fragment>,
                            "reset":
                                <ForgotPassword {...{forgotPassEmail, emailSentError, emailSending, emailSent, onNavigate, onChange, begarNyttLosenord}} />,
                            "reset-callback":
                                <ChangePassword {...{visaLosenord, nyttLosenord, repeteraNyttLosenord, resetError, onNavigate, onChange, bytLosenord, passwordChanging}} />,
                        }[navigation]}

                    </div>
                </div>
            </div>
        )
    }
}