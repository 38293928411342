import React from "react"
import LoadCircle from "components/LoadCircle"
import Input from "components/forms/Input"

export default function ForgotPassword(props) {
    const { forgotPassEmail, emailSentError, emailSending, emailSent, onNavigate, onChange, begarNyttLosenord } = props

    return (
        <React.Fragment>
            <div className="login-input-container">
                <div className="element">
                    <Input label="E-post" placeholder="exempel@sgu.se" value={forgotPassEmail} type="email" name="forgotPassEmail" onChange={onChange} />
                </div>
            </div>

            <div className="element">
                {emailSentError && <p className="element danger small">Något gick fel</p>}
                {emailSending
                    ? <button><LoadCircle color="white" /></button>
                    : <button onClick={begarNyttLosenord} disabled={emailSent}>{ emailSent ? "E-post skickades" : "Skicka" }</button>
                }
            </div>

            <div className="nav-link-container"><span className="nav-link" onClick={() => onNavigate("login")}>Logga in</span></div>
        </React.Fragment>
    )
}