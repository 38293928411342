export default function Input(props) {
    const { style, name, label, onChange, type } = props
    const value = props.value || props.value === 0 ? props.value : ""

    return (
        <div className="input">
            {label && <p className="label">{label}</p>}
            <input
                style={style ? style : {}}
                name={name}
                value={value.toString()}
                type={type}
                onChange={onChange}
            />
        </div>
    )
}