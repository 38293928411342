export default function Checkbox(props) {
    const { style, name, label, onChange } = props
    const value = props.checked || false

    return (
        <label className="checkbox" style={style ? style : {}}>
            <input name={name} checked={value} type={"checkbox"} onChange={onChange} />
            {label && <span className="label">{label}</span>}
        </label>
    )
}