export default class LocalStorage {
    static get(key) {
        try {
            const value = localStorage.getItem(key)
            return value ? JSON.parse(decodeURIComponent(window.atob(value))) : null
        } catch(e) {
            console.error({ status: e, key })
        }
    }

    static set(key, value) {
        try {
            localStorage.setItem(key, window.btoa(encodeURIComponent(JSON.stringify(value))))
        } catch(e) {
            console.error({ status: e, key })
        }
    }

    static remove(key) {
        localStorage.removeItem(key)
    }

    static clear() {
        localStorage.clear()
    }
}