import axios from "axios"
import LocalData from "helpers/LocalStorage"

export default class api {
    static apiRequest(path, data = {}, props = {}) {
        const environment = window.location.origin === process.env.REACT_APP_API_URL_PRODUCTION
            ? process.env.REACT_APP_API_URL_PRODUCTION
            : process.env.REACT_APP_API_URL_DEVELOPMENT

        const apiUrl = environment
        let url = path[0] === "/" ? apiUrl + path : apiUrl + "/" + path

        const headers = {}
        const anvandare = LocalData.get("anvandare")
        headers["Authorization"] = anvandare?.apiNyckel

        const config = {
            method: "post",
            data,
            url,
            ...props,
            headers: { ...headers, ...props.headers }
        }

        return new Promise((resolve, reject) => {
            axios(config).then(response => {
                if (response.data && response.status == 200) { // eslint-disable-line eqeqeq
                    if (props.responseType) {
                        resolve(`data:${response.headers["content-type"]};base64,${Buffer.from(response.data).toString("base64")}`)
                    } else {
                        resolve(response.data)
                    }
                } else {
                    reject(response.data.error)
                }
            }).catch(error => {
                if (error.response) {
                    reject(error.response)
                } else if (error.request) {
                    if (process.env.NODE_ENV === 'development') console.log('request', error.request)
                    if (process.env.NODE_ENV === 'development') console.log('error data', error.data)
                } else {
                    if (error.message === "canceled") resolve("canceled")
                    else reject(error.message)
                }
                if (process.env.NODE_ENV === 'development' && (error && error.response && error.response.status != "404")) console.log('config', error.config) // eslint-disable-line eqeqeq
                reject(error.data)
            })
        })
    }

    static login(krypteratLosenord, anvandarnamn) {
        return this.apiRequest(`/v0/login?password=${krypteratLosenord}&username=${anvandarnamn}`)
    }

    static requestPasswordChange(email) {
        return this.apiRequest(`/reset.php?reset=true&epost=${email}&baseUrl=${window.location.origin}`)
    }

    static changePassword(resetToken, email, nyttLosenord) {
        return this.apiRequest(`reset.php?token=${resetToken}&epost=${email}&newpassword=${nyttLosenord}`)
    }
}