import React from "react"
import Input from "components/forms/Input"
import Checkbox from "components/forms/Checkbox"
import LoadCircle from "components/LoadCircle"

export default function ChangePassword(props) {
    const { visaLosenord, nyttLosenord, repeteraNyttLosenord, resetError, onNavigate, onChange, bytLosenord, passwordChanging } = props

    return (
        <React.Fragment>
            <div className="login-input-container">
                <div className="element">
                    <Input label="Nytt lösenord" value={nyttLosenord} name="nyttLosenord" type={visaLosenord ? "text" : "password"} onChange={onChange} />
                </div>
                <div className="element">
                    <Input label="Repetera lösenord" value={repeteraNyttLosenord} name="repeteraNyttLosenord" type={visaLosenord ? "text" : "password"} onChange={onChange} />
                </div>
                <div className="element">
                    <Checkbox name="visaLosenord" label="Visa lösenord" checked={visaLosenord} onChange={onChange} />
                </div>
            </div>

            <div className="element">
                {resetError && <p className="element danger small">{resetError}</p>}
                {passwordChanging
                    ? <button><LoadCircle color="white" /></button>
                    : <button onClick={bytLosenord}>Skicka</button>
                }
                
            </div>

            <div className="nav-link-container"><span className="nav-link" onClick={() => onNavigate("callback")}>Logga in</span></div>
        </React.Fragment>
    )
}