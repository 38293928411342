import samlaImage from "assets/samlaImage.PNG"
import strukturImage from "assets/strukturImage.PNG"
import sokImage from "assets/sokImage.PNG"
import dokumentationImage from "assets/dokumentationImage.PNG"
import manualImage from "assets/manualImage.PNG"
import azureImage from "assets/azureImage.PNG"
import borraUppladdningImage from "assets/borraUppladdningImage.PNG"
import borraDataImage from "assets/borraDataImage.PNG"
import litRefImage from "assets/litRefImage.PNG"

export default function CardLinks() {
    const baseLocation = window.location.origin

    return (
        <div className="cards-container">
            <div className="card-grid">
                <Card label="Samla" image={samlaImage} redirect={`${baseLocation}/samla`} />
                <Card label="Samla struktur" image={strukturImage} redirect={`${baseLocation}/struktur`} />
                <Card label="Samla sök" image={sokImage} redirect={`${baseLocation}/sok`} />
                <Card label="Dokumentation" image={dokumentationImage} redirect={`${baseLocation}/doc`} />
                <Card label="Manual" image={manualImage} redirect={`${baseLocation}/manual`} />
                <Card label="Azure" image={azureImage} redirect={`${baseLocation}/azure`} />
                <Card label="Borra uppladdning" image={borraUppladdningImage} redirect={`${baseLocation}/borra`} />
                <Card label="Borra data" image={borraDataImage} redirect={`${baseLocation}/bhreg`} />
                <Card label="Litteraturreferenser" image={litRefImage} redirect={`${baseLocation}/litref`} />
            </div>
        </div>
    )
}

function Card(props) {
    const { label, image, redirect } = props

    return (
        <a className="card" href={redirect}>
            <div className="card-content">
                <div className="image" style={{backgroundImage: `url(${image})`}} />
                <div className="strip">
                    {label}
                </div>
            </div>
        </a>
    )
}