import React, { Component } from "react"
import LocalData from "helpers/LocalStorage"
import Home from "components/Home"
import Login from "components/login/Login"

export default class App extends Component {
    constructor(props) {
        super(props)
        let params = new URLSearchParams(window.location.search)

        this.state = {
            anvandare: {},
            apiNyckel: "",
            resetToken: params.get("token"),
            redirect: params.get("login")
        }
    }

    async componentDidMount() {
        const htmlElement = document.querySelector("html")

        let vh = window.innerHeight * 0.01
        htmlElement.style.setProperty("--vh", vh+"px")

        window.addEventListener("resize", (e) => {
            let vh = e.target.innerHeight * 0.01
            htmlElement.style.setProperty("--vh", vh+"px")
        })

        this.login()

        if (process.env.NODE_ENV === "production") {
            window.addEventListener('storage', async () => {
                this.login()
            })
        }
    }

    login = async () => {
        const anvandare = await LocalData.get("anvandare")
        let apiNyckel = anvandare?.apiNyckel

        if (!apiNyckel) {
            apiNyckel = await LocalData.get("apiNyckel")
            if (apiNyckel) {
                anvandare.apiNyckel = apiNyckel
                LocalData.set("anvandare", anvandare)
            }
        }

        if (anvandare && apiNyckel) {
            this.setState({ anvandare, apiNyckel })

            if (this.state.redirect) {
                window.location.replace(`${window.location.origin}/${this.state.redirect.replace(/^\//,"")}`)
            }
        } else this.logout()
    }

    logout = async () => {
        LocalData.remove("anvandare")
        LocalData.remove("apiNyckel")
        LocalData.remove("installningar")
        LocalData.remove("lastSGUidSerial")

        this.setState({ anvandare: {}, apiNyckel: "" })
    }

    render() {
        const { anvandare, apiNyckel, resetToken, redirect } = this.state
        const isLoggedIn = apiNyckel && anvandare && anvandare.namnkod && !resetToken && !redirect

        return (
            isLoggedIn
                ? <Home anvandare={anvandare} logout={this.logout} />
                : <Login login={this.login} />
        )
    }
}