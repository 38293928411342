import CardLinks from "components/CardLinks"
import samlaLogo from "assets/logo-samla-login.svg"
import { ReactComponent as LogoutIcon } from "assets/logoutIcon.svg"

export default function Home(props) {
    return (
        <div className="home">
            <div className="header">
                <img alt="" src={samlaLogo} />
                <h1>E</h1>
                <h1>f</h1>
                <h1>f</h1>
                <h1>e</h1>
                <h1>k</h1>
                <h1>t</h1>
            </div>

            <div className="content">
                <CardLinks />
            </div>

            <div className="footer">
                <div className="logout" onClick={props.logout} title="Logga ut">
                    <p>{props.anvandare.namn}</p>
                    <LogoutIcon />
                </div>
            </div>
        </div>
    )
}